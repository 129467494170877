<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-modal
      v-model:show="isModalVisible"
      class="modal-component"
      :class="fullScreen ? 'full-screen' : ''"
      :style="styles"
      preset="dialog"
      :show-icon="false"
      :mask-closable="maskClosable"
      @update:show="handleShowUpdate"
    >
      <template #header>
        <slot name="header" />
      </template>
      <slot />

      <template #action>
        <slot name="action" />
      </template>
    </n-modal>
  </n-config-provider>
</template>

<script lang="ts" setup>
const isModalVisible = ref<boolean>(false);
const themeOverrides = getConfirmModalThemeOverrides();

const { width } = defineProps({
  width: { type: String, required: false, default: '600px' },
  fullScreen: { type: Boolean, required: false, default: false },
  maskClosable: { type: Boolean, required: false, default: true },
});
const emits = defineEmits<{
  (e: 'onShowUpdate', value: boolean): void;
}>();

const styles = computed(() => {
  return { width: width + ' !important' };
});

const openModal = () => {
  isModalVisible.value = true;
};

const closeModal = () => {
  isModalVisible.value = false;
};

defineExpose({ openModal, closeModal });

function handleShowUpdate(value: boolean): void {
  emits('onShowUpdate', value);
}
</script>

<style lang="scss">
.n-dialog.n-modal {
  max-width: calc(100% - 3.5rem);
  max-height: 100%;
  height: calc(100% - 62px);
}

.modal-component {
  border-radius: 0.3rem;
}

.n-base-close {
  background-color: white;
  opacity: 0.5;

  &:hover {
    opacity: 0.75;
  }
}

.n-dialog {
  & .n-dialog__title {
    padding: 1rem;
    border-bottom: 2px solid #4c4c4c;
  }

  & .n-dialog__content {
    padding: 1rem;
  }

  & .n-dialog__action {
    justify-content: center;
    padding: 1rem;
    padding-top: 0;
  }
}

.full-screen .n-dialog__title:has(div:empty) {
  padding: 0 !important;
  border: none;
}

.full-screen.n-dialog.n-modal {
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
}

.n-modal-scroll-content:has(.sr-iframe) .full-screen .n-dialog__content {
  height: 100%;
}
</style>

<style scoped lang="scss"></style>
